import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from 'components/Container/Container';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Title from 'components/Title/Title';

import { grid, hero, outerContainer, container } from './Hero.module.scss';

const mb = { marginBottom: '1.5rem' };
const fullWidth = { width: '100%' };

const Hero = ({ children, title }) => {
  return (
    <Layout fullWidth={true}>
      <Seo title={title} />
      <div className={grid}>
        <StaticImage
          layout="fullWidth"
          alt=""
          src={'../../../images/login.jpg'}
          formats={['auto', 'webp', 'avif']}
          className={hero}
        />
        <div className={outerContainer}>
          <Container withPadding={true} style={fullWidth}>
            <Title isBanner={true} style={mb}>
              {title}
            </Title>
            <div className={container}>{children}</div>
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default Hero;
